<template>
  <div id="ContactUs">
    <Navbar>
      <span> {{ $t(`navBarMoomall.contact`) }}</span>
    </Navbar>
    <v-main>
      <CardContactList />
    </v-main>
  </div>
</template>

<script>
import CardContactList from '@/components/Main/Content-Moomall/CardContactList'

export default {
  name: 'ContactUs',
  components: { CardContactList },
  mounted() {
    window.scrollTo(0, 0)
  },
  data() {
    return {}
  },
}
</script>

<style scoped></style>
