<template>
  <!-- NOTE: ปรับ mb-5 pb-5 ดันส่วนล่างขึ้นมา -->
  <div class="mb-5 pb-5">
    <div v-for="(card, index) in contact" :key="index">
      <v-card class="mb-2 pa-2" elevation="0" style=" border-radius: 20px;">
        <div
          style="background-color: #FBE7E7; border-top-left-radius: 20px; border-top-right-radius: 20px;"
          class="text-title mx-n2 pa-2 mt-n2 pl-4"
        >
          {{ $t(`contact.${card.title}`) }}
        </div>
        <v-list
          subheader
          three-line
          class="mx-n2 my-0 pb-0"
          v-for="(item, index) in card.details"
          :key="index"
        >
          <v-divider class="ma-0 pa-0" v-if="index > 0"></v-divider>

          <v-list-item @click="toUrl(item)">
            <BaseIcon
              class="pr-4"
              :nameIcon="item.image_id"
              width="58"
              height="58"
              cssIcon="mx-auto mb-2"
            />
            <v-list-item-content class="pt-0">
              <v-list-item-title class="mt-3 pb-0 mb-0">{{
                $t(`contact.${item.title}`)
              }}</v-list-item-title>
              <v-list-item-subtitle class="pt-n2">{{
                item.subtitle || $t(`contact.${item.subtitleLang}`)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Main/BaseIcon'

export default {
  name: 'CardContactList',
  components: { BaseIcon },
  data() {
    return {
      contact: [
        {
          title: 'pacer',
          details: [
            {
              image_id: 'icon-facebook',
              title: 'fbFanPage',
              subtitle: 'moomall official',
              andriod: 'fb://page/104865040892152',
              ios: 'fb://page/?id=104865040892152',
              toUrl: 'https://www.facebook.com/moomallofficial',
            },
            {
              image_id: 'icon-line',
              title: 'lineGroup',
              subtitle: 'Line@moomallOfficial',
              andriod: 'line://ti/p/@moomallofficial',
              ios: 'line://ti/p/@moomallofficial',
              toUrl: 'https://line.me/ti/p/@moomallofficial',
            },
            {
              image_id: 'icon-facebook-moomall',
              title: 'fbGroup',
              subtitleLang: 'fbGroupDetail',
              andriod: 'fb://group/573261383313700',
              ios: 'fb://group?id=573261383313700',
              toUrl: 'https://www.facebook.com/groups/573261383313700',
            },
            {
              image_id: 'moomall-academy',
              title: 'moomallAcademy',
              subtitleLang: 'mootrainingDetails',
              andriod: 'fb://group/528207501541271',
              ios: 'fb://group?id=528207501541271',
              toUrl: 'https://www.facebook.com/groups/528207501541271',
            },
            {
              image_id: 'icon-ig',
              title: 'instagram',
              subtitle: 'moomall official',
              andriod: 'instagram://user?username=moomallofficial',
              ios: 'instagram://user?username=moomallofficial',
              toUrl: 'instagram://www.instagram.com/moomallofficial/',
            },
            {
              image_id: 'icon-youtube',
              title: 'youtube',
              subtitle: 'moomall official',
              andriod:
                'vnd.youtube://www.youtube.com/channel/UCKeCQ5aR6gMQcxUd9trx0_Q',
              ios: 'youtube://www.youtube.com/channel/UCKeCQ5aR6gMQcxUd9trx0_Q',
              toUrl: 'https://www.youtube.com/channel/UCKeCQ5aR6gMQcxUd9trx0_Q',
            },
            {
              image_id: 'icon-twitter',
              title: 'twitter',
              subtitle: 'moomall official',
              andriod: 'twitter://user?screen_name=@moomallofficial',
              ios: 'twitter://user?screen_name=@moomallofficial',
              toUrl: 'https://twitter.com/@moomallofficial',
            },
          ],
        },
        {
          title: 'shop',
          details: [
            {
              image_id: 'icon-seller',
              title: 'shopDetail',
              subtitle: 'Line@sellermoo',
              andriod: 'line://ti/p/@sellermoo',
              ios: 'line://ti/p/@sellermoo',
              toUrl: 'https://line.me/ti/p/@sellermoo',
            },
            {
              image_id: 'icon-partner',
              title: 'openShop',
              subtitle: 'Line@moomallpartner',
              andriod: 'line://ti/p/@moomallpartner',
              ios: 'line://ti/p/@moomallpartner',
              toUrl: 'https://line.me/ti/p/@moomallpartner',
            },
          ],
        },
        {
          title: 'contactMoomall',
          details: [
            {
              image_id: 'icon-contact-phone-new',
              title: 'contactPhone',
              subtitleLang: 'contactDetail',
              andriod: 'tel:021146595',
              ios: 'tel:021146595',
              toUrl: 'tel:021146595',
            },
          ],
        },
      ],
    }
  },
  methods: {
    toUrl(path) {
      if (
        /webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        open(path.ios)
      } else if (/Android/i.test(navigator.userAgent)) {
        open(path.andriod)
      } else {
        open(path.toUrl)
      }
    },
  },
}
</script>

<style scoped>
.text-title {
  font-size: 14px;
  font-weight: Bold;
}

.text-subtitle {
  color: #707070;
  font-size: 14px;
}

.line {
  border-top: 1px solid #707070;
}

.moomall_link {
  color: #000000;
  text-decoration: none;
}
</style>
